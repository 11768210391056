<template>
  <div class="domainBackGround" id="domainsAll">
    <!-------------------------------------------NAVBAR-------------------------------------------------->
    <div id="appbar">
      <text @click="navToHome" class="title" id="hiddenTitle">
        &nbsp; &nbsp; &nbsp; &nbsp;DDC IoT Platform</text
      >

      <div class="container">
        <div class="item">
          <img :src="require('../../assets/logo2.png')" />
        </div>
      </div>
      <button
        type="button"
        id="navButtonHomeDomains"
        class="btn btn-success"
        @click="navToHome"
      >
        <i class="fa-solid fa-house"></i> <text class="navText">Home</text>
      </button>
      <button
        type="button"
        id="navButtonActiveDomains"
        class="btn btn-success"
        @click="navToDomains"
      >
        <i class="fa-solid fa-layer-group"></i>
        <text class="navText"> Domains</text>
      </button>
      <button
        type="button"
        id="navButtonDevicesDomains"
        class="btn btn-success"
        @click="navToDevices"
      >
        <i class="fas fa-hdd"></i> <text class="navText">Devices</text>
      </button>
      <button
        type="button"
        id="navButtonUsersDomains"
        class="btn btn-success"
        @click="navToUsers"
      >
        <i class="fa-solid fa-users"></i> <text class="navText">Users</text>
      </button>
      <router-link to="/domains" class="Router-link"
        ><i class="fas fa-layer-group"></i>
      </router-link>
      <router-link to="/devices" class="Router-link"
        ><i class="fas fa-chalkboard"></i
      ></router-link>
      <router-link to="/users" class="Router-link"
        ><i class="fas fa-user-friends"></i> Users</router-link
      >
      <div id="profileIcon">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="logoutButtonDomains"
          @click="profilename"
        >
          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
          <label class="usernameText">{{ this.user }}</label>

          <i class="fa-solid fa-caret-down" id="pIcon"></i> &nbsp;
        </button>
        <div class="dropdown-menu">
          <a @click="navToSettings" class="dropdown-item" id="blockedContent"
            >Settings <i class="fas fa-cogs" id="settingsIcon"></i
          ></a>
          <a @click="logout" class="dropdown-item"
            >Logout <i class="fas fa-sign-out-alt" id="logoutIcon"></i
          ></a>
        </div>
      </div>
    </div>
    <!-- -------------------------------------------Domains CArd------------------------------------------ -->
    <div class="domainsCard" id="domainContainer">
      <h4 id="domainsTitle">Domains</h4>
      <p v-if="this.error" class="errorClass">{{ this.error }}</p>
      <!-- ----------------------------SEARCH BAR----------------------- -->
      <div id="searchDiv">
        <input
          type="text"
          placeholder="Search..."
          id="searchBar"
          @keydown="isSearchEnter()"
        />
        <button class="searchButton" @click="searchName()" id="searchButton">
          <i id="filterIcon" class="fas fa-filter"></i>
        </button>
        <!-- ----------------------------CREATE BUTTON----------------------- -->
        <button
          type="button"
          id="domainCreateButton"
          class="btn btn-outline-success"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCreate"
        >
          <i class="fas fa-plus"></i>
          <text id="hiddenCreateText"> Create </text>
        </button>
      </div>
      <!-- ----------------------------CREATE, Create MODAL----------------------- -->

      <div
        class="modal fade"
        id="exampleModalCreate"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="createDomainTitle">Create Domain</h2>
              <h5 class="modal-title" id="exampleModalLabel"></h5>
            </div>
            <div class="modal-body">
              <div class="bodyContent">
                Please fill the form if you want to create a new domain
                <br />
                <h3>{{ createError }}</h3>

                <label for="name">Domain name:</label>
                <input
                  v-model="createName"
                  type="text"
                  name="Name"
                  id="domainName"
                  placeholder="Name"
                  @keydown="isCreateEnter"
                />
                <br />
                <br />
                <label for="info">Domain info:</label>
                <input
                  v-model="createInfo"
                  type="text"
                  name="Info"
                  id="domainInfo"
                  placeholder="INFO"
                  @keydown="isCreateEnter"
                />
                <br />
                <br />
              </div>
            </div>
            <div class="modal-footer">
              <div class="footerButtons">
                <button
                  type="button"
                  id="sureButtonDomains"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  @click="createDomain()"
                >
                  Create
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-danger"
                  id="cancelButtonDomainsCreate"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ---------------------------------------------------------TABLE ------------------------------------------------------------ -->
      <table class="table table-dark" id="domainsTable">
        <tbody>
          <tr>
            <td>
              <input
                class="form-check-input flexCheckDefault"
                type="checkbox"
                value=""
                id="flexCheckDefaultAll"
                @click="checkAllBoxes"
              />
            </td>
            <th id="tableCells" class="thdomains">Name</th>
            <th id="tableCells" class="thdomains">Info</th>
            <th id="tableCells" class="thdomains">Uuid</th>
            <th id="tableCells"></th>
            <th id="tableCells"></th>
          </tr>
          <tr
            class="domainsTableRow"
            v-for="(item, index) in num"
            :key="item.uuid"
          >
            <td
              v-if="
                (this.user != 'ewgtestuser1' && this.user != 'ewgtestuser2') ||
                ((this.user == 'ewgtestuser1' || this.user == 'ewgtestuser2') &&
                  (item.name == 'ewg-transport' || item.name == 'ewg-personal'))
              "
            >
              <input
                class="form-check-input flexCheckDefault"
                type="checkbox"
                value=""
                :id="'flexCheckDefault' + index"
              />
            </td>
            <td
              v-if="
                (this.user != 'ewgtestuser1' && this.user != 'ewgtestuser2') ||
                ((this.user == 'ewgtestuser1' || this.user == 'ewgtestuser2') &&
                  (item.name == 'ewg-transport' || item.name == 'ewg-personal'))
              "
              id="tableCells"
              @click="toDeviceList(item), getDomainName(index)"
            >
              {{ names[index] }}
            </td>
            <td
              v-if="
                (this.user != 'ewgtestuser1' && this.user != 'ewgtestuser2') ||
                ((this.user == 'ewgtestuser1' || this.user == 'ewgtestuser2') &&
                  (item.name == 'ewg-transport' || item.name == 'ewg-personal'))
              "
              id="tableCells"
              @click="toDeviceList(item)"
            >
              {{ info[index] }}
            </td>
            <td
              v-if="
                (this.user != 'ewgtestuser1' && this.user != 'ewgtestuser2') ||
                ((this.user == 'ewgtestuser1' || this.user == 'ewgtestuser2') &&
                  (item.name == 'ewg-transport' || item.name == 'ewg-personal'))
              "
              id="tableCells"
              @click="toDeviceList(item)"
            >
              {{ uuid[index] }}
            </td>
            <td
              v-if="
                (this.user != 'ewgtestuser1' && this.user != 'ewgtestuser2') ||
                ((this.user == 'ewgtestuser1' || this.user == 'ewgtestuser2') &&
                  (item.name == 'ewg-transport' || item.name == 'ewg-personal'))
              "
              id="tableCells"
            ></td>
            <td
              v-if="
                (this.user != 'ewgtestuser1' && this.user != 'ewgtestuser2') ||
                ((this.user == 'ewgtestuser1' || this.user == 'ewgtestuser2') &&
                  (item.name == 'ewg-transport' || item.name == 'ewg-personal'))
              "
              id="tableCells"
            >
              <!-- ---------------------------------------------DELETE------------------------------------------------------ -->
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                :id="'domainDeleteButton' + index"
                @click="setIndexDomainValue(item, index)"
              >
                <i class="fas fa-trash-alt" id="trashIcon"></i>
                <text id="hiddenDeleteText">&nbsp;Delete</text>
              </button>
              <!-- ----------------------------------------EDIT------------------------------------------------ -->
              <button
                :id="'domainEditButton' + index"
                type="button"
                class="btn btn-Edit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalEdit"
                @click="editDomainModal(item, index)"
              >
                <i class="far fa-edit"></i>
                <text id="hiddenEditText"> Edit</text>
              </button>

              <!----------------------------------------------- DELETE Modal -------------------------------------------->
              <div
                class="modal fade"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </div>
                    <div class="modal-body">
                      Are you sure you want to delete "{{
                        names[indexDomainValue]
                      }}" domain?
                    </div>
                    <div class="modal-footer">
                      <div class="footerButtons">
                        <button
                          type="button"
                          class="btn btn-success"
                          id="sureButtonDomains"
                          data-bs-dismiss="modal"
                          @click="
                            deleteDomain(this.itemDomainValue, indexDomainValue)
                          "
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="btn btn-danger"
                          id="cancelButtonDomains"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -------------------------------------------EDIT MODAL--------------------------------------------- -->
              <div
                class="modal fade"
                id="exampleModalEdit"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h2 id="editDeviceTitle">
                        Editing Domain: {{ this.editName }}
                      </h2>
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </div>
                    <div class="modal-body">
                      Please fill the form if you want to edit this Domain.
                      <br />
                      <br />
                      <label for="name">Domain name:</label>
                      <input
                        v-model="editName"
                        type="text"
                        name="Name"
                        id="editDomainName"
                        placeholder="Domain name"
                        @keydown="isEditEnter"
                      />
                      <br />
                      <br />
                      <label for="info" id="domainInfoLabel"
                        >Domain Info:</label
                      >
                      <input
                        v-model="editInfo"
                        type="text"
                        name="PhysicalId"
                        id="editDomainInfo"
                        placeholder="Info"
                        @keydown="isEditEnter"
                      />
                      <br />
                      <br />
                      <label for="roles" id="domainRolesLabel">Roles:</label>
                      <p>Domain_Admin</p>
                      <i class="fa-solid fa-trash" v-if="this.hasAdminRoleVar" @click="deleteRole('domain_admin')"></i>
                      <i v-if="this.hasAdminRoleVar==false" class="fa-solid fa-plus" @click="addRole('domain_admin')"></i>
                      <p>Domain_operator</p>
                      <i class="fa-solid fa-trash" v-if="this.hasOperatorRoleVar" @click="deleteRole('domain_operator')"> </i
                      ><i v-if="this.hasOperatorRoleVar==false" class="fa-solid fa-plus" @click="addRole('domain_operator')"></i>
                      <p>Domain_user</p>
                      <i class="fa-solid fa-trash" v-if="this.hasUserRoleVar" @click="deleteRole('domain_user')"></i>
                      <i v-if="this.hasUserRoleVar==false" class="fa-solid fa-plus" @click="addRole('domain_user')"></i>

                      <br />
                      <br />
                    </div>
                    <div class="modal-footer">
                      <div class="footerButtons">
                        <button
                          type="button"
                          id="saveDomainEdit"
                          class="btn btn-success"
                          data-bs-dismiss="modal"
                          @click="editDomain()"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          id="cancelDomainEdit"
                          data-bs-dismiss="modal"
                          class="btn btn-danger"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="spinner-domains" class="spinner-container">
      <atom-spinner
        :animation-duration="1000"
        :size="120"
        :color="'#42ba85'"
      />
    </div>
  </div>
</template>

<script>
import ddcIotAuthProvider from "../../utils/ddcIotAuthProvider.js";
import { AtomSpinner } from "epic-spinners";

const apiUrl =
  process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";
const authProvider = ddcIotAuthProvider(apiUrl);
import ddcIotDataProvider from "../../utils/ddcIotDataProvider";
const dataProvider = ddcIotDataProvider(apiUrl);

export default {
  components: {
    AtomSpinner,
  },
  /* --------------------------------------------METHODS---------------------------------------- */
  methods: {
    
    //--------------------------------------ROLEs--------------------------------------------------
    async addRole(role){
    console.log("ADDROLE: ", role)
    await dataProvider.addDomainRole(this.editUuid, role)
    this.hasRoles()

    },
    async deleteRole(role){
      console.log("DELETEROLE: ",role)
      await dataProvider.deleteDomainRoles(this.editUuid, role)
      this.hasRoles()


    },

    async hasRoles() {
      if (this.editUuid) {
        let rolearray = await dataProvider.getDomainRoles(this.editUuid);
        console.log(rolearray)
        this.hasAdminRoleVar = false;
        this.hasOperatorRoleVar = false;
        this.hasUserRoleVar = false;

        rolearray.map((data) => {
          if (data.name == "domain_admin") {
            this.hasAdminRoleVar = true;
          }
          if (data.name == "domain_operator") {
            this.hasOperatorRoleVar = true;
          }
          if (data.name == "domain_user") {
            this.hasUserRoleVar = true;
          }
        });
        console.log(this.hasAdminRoleVar,this.hasOperatorRoleVar,this.hasUserRoleVar)
    document.getElementById("spinner-domains").style.display = "none";

      }
    },
    checkAllBoxes() {
      for (let index = 0; index < this.num.length; index++) {
        document.getElementById("flexCheckDefault" + index).click();
      }
    },
    //----------------------------SEARCH BAR----------------------- -->
    searchName() {
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("searchBar");
      filter = input.value.toUpperCase();
      table = document.getElementById("domainsTable");
      tr = table.getElementsByTagName("tr");
      //console.log(filter, tr.length);
      for (i = 1; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[1];
        if (td) {
          txtValue = td.textContent || td.innerText;
          //console.log(input);
          if (txtValue.toUpperCase().includes(filter)) {
            tr[i].style.display = "";
          } else {
            //console.log(txtValue.toUpperCase(), filter);
            tr[i].style.display = "none";
          }
        }
      }
    },
    isSearchEnter() {
      if (event.keyCode == 13) {
        document.getElementById("searchButton").click();
      }
    },
    ///-------------------------------------CREATE-----------------------------------------
    isCreateEnter() {
      if (event.keyCode == 13) {
        document.getElementById("sureButtonDomains").click();
      }
    },
    //////---------------------------------------EDIT---------------------------------------------
    isEditEnter() {
      if (event.keyCode == 13) {
        document.getElementById("saveDomainEdit").click();
      }
    },
    /* ----------------------------------------LOGOUT-------------------------------- */
    logout() {
      authProvider.logout();
      this.loggedIn = false;
      this.$router.push("/Login");
      //console.log(this.loggedIn);
      localStorage.setItem("username", "");
    },
    /* ----------------------------------------------NAVBAR--------------------------------------------- */
    profilename() {
      //console.log(localStorage.getItem("username"));
    },

    titleHide() {
      if (window.width <= 5000) {
        document.getElementById("hiddenTitle").style.color = "red";
      }
    },

    navToDevices() {
      this.$router.push("/devices");
    },

    navToHome() {
      this.$router.push("/");
    },

    navToSettings() {
      this.$router.push("/settings");
    },

    navToDomains() {
      this.$router.push("/domains");
    },

    navToUsers() {
      this.$router.push("/users");
    },
    /* ----------------------------------------------THEME CHANGER------------------------------------------- */
    themeChanger() {
      var themeValue = localStorage.getItem("theme");

      if (themeValue == "light") {
        document.getElementById("domainsAll").style.backgroundColor = "white";

        document.getElementById("navButtonUsers").style.color = "black";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonDevices").style.color = "black";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonHome").style.color = "black";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#212529";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "#d2d3db";
        document.getElementById("logoutButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("logoutButton").style.color = "black";
        document.getElementById("domainContainer").style.backgroundColor =
          "#d2d3db";
        document.getElementById("domainContainer").style.color = "black";
        document.getElementById("logo").style.color = "black";
        document.getElementById("domainCreateButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("domainCreateButton").style.color = "black";
        document.getElementById("domainCreateButton").style.border =
          "1px solid black";
        document.getElementById("domainsTable").className = "table";
        document.getElementById("domainsTable").style.color = "black";
      }

      if (themeValue == "blue") {
        document.getElementById("domainsAll").style.backgroundColor = "white";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonUsers").style.color = "white";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonDevices").style.color = "white";
        document.getElementById("navButtonHome").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonHome").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "dodgerblue";
        document.getElementById("logoutButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("logoutButton").style.color = "white";
        document.getElementById("domainContainer").style.backgroundColor =
          "dodgerblue";
        document.getElementById("domainContainer").style.color = "white";
        document.getElementById("logo").style.color = "white";
        document.getElementById("domainCreateButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("domainCreateButton").style.color = "white";
        document.getElementById("domainCreateButton").style.border =
          "1px solid white";
        document.getElementById("domainsTable").className = "table";
        document.getElementById("domainsTable").style.color = "white";
      }

      if (themeValue == "green") {
        document.getElementById("domainsAll").style.backgroundColor = "white";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonUsers").style.color = "white";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonDevices").style.color = "white";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonHome").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "#42ba85";
        document.getElementById("logoutButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("logoutButton").style.color = "white";
        document.getElementById("domainContainer").style.backgroundColor =
          "#42ba85";
        document.getElementById("domainContainer").style.color = "white";
        document.getElementById("domainCreateButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("domainCreateButton").style.color = "white";
        document.getElementById("domainCreateButton").style.border =
          "1px solid white";
        document.getElementById("logo").style.color = "white";
        document.getElementById("domainsTable").className = "table";
        document.getElementById("domainsTable").style.color = "white";
      }

      if (themeValue == "cyan") {
        document.getElementById("domainsAll").style.backgroundColor = "#2a323d";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonUsers").style.color = "#2a323d";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonDevices").style.color = "#2a323d";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonHome").style.color = "#2a323d";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#2a323d";
        document.getElementById("navButtonActive").style.color = "#159fba";
        document.getElementById("appbar").style.backgroundColor = "#159fba";
        document.getElementById("logoutButton").style.backgroundColor =
          "#159fba";
        document.getElementById("logoutButton").style.color = "#2a323d";
        document.getElementById("domainContainer").style.backgroundColor =
          "#159fba";
        document.getElementById("domainContainer").style.color = "#2a323d";
        document.getElementById("domainCreateButton").style.backgroundColor =
          "#2a323d";
        document.getElementById("domainCreateButton").style.color = "#159fba";
        document.getElementById("domainCreateButton").style.border =
          "1px solid #159fba";
        document.getElementById("logo").style.color = "#2a323d";
        document.getElementById("domainsTable").className = "table";
        document.getElementById("domainsTable").style.color = "#2a323d";
      }
    },

    //--------------------------------------CREATE DOMAIN---------------------------------------
    async createDomain() {
      let res = await dataProvider.getList("domains");
      //console.log(res);
      let wrong = false;
      for await (let rows of res.data) {
        //console.log(rows);
        if (rows.name == this.createName) {
          wrong = true;
        }
      }
      if (wrong) {
        this.error = "Domain already exists in another user or in yours.";
        const newArray = await dataProvider.getList("domains");
        //console.log(newArray);
        this.names = [];
        this.uuid = [];
        this.info = [];
        for (let rows of newArray.data) {
          this.names.push(rows.name);
          this.uuid.push(rows.uuid);
          this.info.push(rows.info);
        }
        this.num = newArray.data;
      } else {
        this.error = "";
        var params = {
          data: {
            name: this.createName,
            info: this.createInfo,
          },
        };
        await dataProvider.create("domains", params);
        //console.log("Create Domain!");
        const newArray = await dataProvider.getList("domains");
        //console.log(newArray);
        this.names = [];
        this.uuid = [];
        this.info = [];
        for (let rows of newArray.data) {
          this.names.push(rows.name);
          this.uuid.push(rows.uuid);
          this.info.push(rows.info);
        }
        this.num = newArray.data;
        //console.log(this.names, this.uuid, this.info);
      }
    },
    ////////------------------------------------------------------EDIT DOMAIN-------------------------------------------
    editDomainModal(item, index) {
      //console.log(item);
    document.getElementById("spinner-domains").style.display = "block";

      this.editUuid = item.uuid;
      this.editName = item.name;
      this.editInfo = item.info;
      this.editIndex = index;
      this.hasRoles()


      //console.log(item, index);
    },
    async editDomain() {
      //console.log(this.editName, this.editInfo, this.editUuid);
      let params = {
        data: {
          name: this.editName,
          info: this.editInfo,
          uuid: this.editUuid,
        },
      };
      let wrong = false;
      let domains = await dataProvider.getList("domains");
      let index = -1;
      for await (let rows of domains.data) {
        index++;
        //console.log(rows.name, this.editName, rows.info, this.editInfo, index, this.editIndex);
        if (rows.name == this.editName && this.editIndex != index) {
          wrong = true;
        }
        //console.log(wrong);
      }
      if (wrong == true) {
        this.error = "Domain already exists";
      } else {
        this.error = "";
        dataProvider
          .update("domains", params)
          .then(() => {
            //console.log("Edit Domain!");
            this.names[this.editIndex] = this.editName;
            this.info[this.editIndex] = this.editInfo;
            dataProvider.getList("domains").then((res) => {
              this.names = [];
              this.uuid = [];
              this.info = [];
              for (let rows of res.data) {
                this.names.push(rows.name);
                this.uuid.push(rows.uuid);
                this.info.push(rows.info);
              }
              this.num = res.data;
              //console.log(this.names, this.uuid, this.info);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    ////----------------------------------------------------TABLE FUNCTIONS-------------------------------------
    getDomainName(index) {
      localStorage.setItem("actualDomain", this.names[index]);
      //console.log(localStorage.getItem("actualDomain"));
    },

    setIndexDomainValue(item, index) {
      this.indexDomainValue = index;
      this.itemDomainValue = item;
    },
    toDeviceList(params) {
      localStorage.setItem("domain", params.uuid);
      this.$router.push("./devices");
    },
    //-------------------------------------------------------DELETE DOMAIN---------------------------------------------
    deleteDomain(params, index) {
      //console.log(this.uuid);
      var param = {
        id: params.uuid,
      };
      if (params.name != "demo_domain") {
        document
          .getElementById("domainDeleteButton" + index)
          .setAttribute("disabled", "disabled");
        //console.log("Delete domain!");
        dataProvider
          .delete("domains", param)
          .then(() => {
            this.num.splice(index, 1);
            this.names.splice(index, 1);
            this.uuid.splice(index, 1);
            this.info.splice(index, 1);
            //console.log(this.num);
          })
          .catch(() => {
            this.error = "Cannot delete domain with devices";
            alert("Cannot delete domain with devices");
            document
              .getElementById("domainDeleteButton" + index)
              .removeAttribute("disabled");
          });
      } else {
        this.error = "Cannot delete demo domain";
        alert("Cannot delete demo domain");
      }
    },
  },
  mounted() {
    //console.log("mounted!");
    document.getElementById("spinner-domains").style.display = "block";
    this.themeChanger();

    dataProvider
      .getList("domains")
      .then((res) => {
        return (
          res.data.map((data) => {
            this.uuid.push(data.uuid);
            this.names.push(data.name);
            this.info.push(data.info);
            document.getElementById("spinner-domains").style.display = "none";
          }),
          (this.num = res.data)
        );
      })
      .catch((err) => console.log(err));

    if (
      localStorage.getItem("domain") == "c57bd007-fe4b-4555-b464-21b8bb920482"
    ) {
      document.getElementById("navButtonDevicesDomains").disabled = "disabled";
    } else {
      document.getElementById("navButtonDevicesDomains").enabled = "enabled";
    }
  },
  updated() {
    //console.log("updataed!");
  },
  ///Variables u can declare
  data() {
    return {
      //--------------ROLE-------------------
      hasAdminRoleVar:undefined,
      hasOperatorRoleVar:undefined,
      hasUserRoleVar:undefined,
      //-------------TABLE----------------//
      names: [],
      uuid: [],
      info: [],
      mode: false,
      num: 0,
      indexDomainValue: 0,
      error: null,
      domain: null,
      user: localStorage.getItem("username"),
      //------------EDIT--------------//
      editName: null,
      editInfo: null,
      editIndex: null,
      editUuid: null,
      editError: null,
      array: [],
      Selected: null,
      //------------CREATE---------------//
      createName: null,
      createInfo: null,
      createError: null,
      //--------------DELETE-------------//
    };
  },
};
</script>

<style lang="scss" scoped>
.roles {
  position: relative;
  float: right;
}
.roleIcons {
  position: relative;
  float: right;
}
.errorClass {
  position: relative;
  top: 30px;
  left: 10px;
  color: #ff4f60;
  font-size: 20px;
}

/* ---------------SEARCH STYLE--------------------- */
#searchDiv {
  position: relative;
  top: 2rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 2rem;
}
#searchBar {
  position: relative;
  margin-left: 10px;
  height: 40px;
  color: white;
  min-width: 110px;
  max-width: 115px;
  background-color: #20262e;
  border-radius: 5px 0px 0px 5px;
  float: left;
  border: 1px solid white;
}

#searchButton {
  background-color: #42ba85;
  border: 0;
  color: white;
  height: 40px;
  width: 50px;
  min-width: 50px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  float: left;
}
/* ---------------TABLE STYLE--------------------- */
.domainsTable {
  width: 100vw;
  color: white;
}
.flexCheckDefault {
  background-color: #2a323d;
  border: 1px solid #42ba85;
}
/* ----------------------------------NAVBAR----------------------------- */
#appbar {
  min-height: 50px;
  max-height: 60px;
}

#hiddenTitle {
  font-size: 20px;
}

#logoutButtonDomains {
  position: fixed;
  float: right;
  right: 30px;
  background-color: #20262e;
  display: flex;
  top: 0.1vh;
  width: 7%;
  border: 0;
  cursor: pointer;
  min-width: 150px;
  max-width: 200px;
}

#logoutButtonDomains:hover {
  color: #42ba85;
  cursor: pointer;
}

#logoutButtonDomains:focus {
  outline: none;
  box-shadow: none;
}

#navButtonHomeDomains {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonHomeDomains:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonHomeDomains:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonDevicesDomains {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonDevicesDomains:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDevicesDomains:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonUsersDomains {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonUsersDomains:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonUsersDomains:hover {
  background-color: #42ba85;
  color: black;
}

#navButtonActiveDomains {
  background-color: #42ba85;
  color: black;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonActiveDomains:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonActiveDomains:hover {
  background-color: #42ba85;
  color: black;
}
/* --------------- Domain CARD STYLE--------------------- */

.domainsCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 92.5vw;
  border-radius: 5px;
  top: 12vh;
  color: white;
  background-color: #212529;
  position: relative;
  margin: auto;
  min-width: 50px;
}

.domainsCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* --------------- SURE BUTTON STYLE--------------------- */
.btn-success {
  border: 0px solid red;
}
#saveDomainEdit {
  background-color: #42ba85;
  float: left;

  width: 110px;
  max-width: 150px;
  min-width: 110px;
}
#saveDomainEdit:hover {
  background-color: #35976d;
}
#sureButtonDomains {
  background-color: #42ba85;

  float: left;
  width: 110px;
  max-width: 150px;
  min-width: 110px;
}

#spinner-domains {
  position: absolute;
  top: 50vh;
  left: 50vw;
  display: none;
  z-index: 2000;
}

#sureButtonDomains:hover {
  background-color: #35976d;
}

#sureButtonDomains:active {
  box-shadow: none;
  outline: none;
}
/* --------------- CANCEL BUTTON STYLE--------------------- */
#cancelButtonDomains {
  position: relative;
  float: right;
  margin-left: 3px;
  width: 110px;
  max-width: 150px;
  min-width: 110px;
}
#cancelButtonDomains:hover {
  color: white;
  background-color: #bb2d3b;
}
#cancelButtonDomainsCreate {
  position: relative;
  float: right;
  margin-left: 3px;
}
#cancelButtonDomainsCreate:hover {
  color: white;
  background-color: #bb2d3b;
}

#cancelButtonDomains:active {
  box-shadow: none;
  outline: none;
}
#cancelDomainEdit {
  float: right;
  margin-left: 3px;
}

/* --------------- DELETE Button STYLE--------------------- */
.btn {
  width: 7vw;
  min-width: 75px;
  float: right;
}
.btn-danger {
  background-color: #dc3545;
  margin-right: 5px;
  width: 7vw;
  min-width: 75px;
  float: right;
  max-width: 150px;
  min-width: 110px;
}
#hiddenDeleteText {
  bottom: 0.5vh;
}
#trashIcon {
  bottom: 0.5vh;
}
/* --------------- MODAL STYLE--------------------- */
.bodyContent {
  margin: auto;
}
.footerButtons {
  margin: auto;
}
/* --------------- EDIT MODAL STYLE--------------------- */
.btn-Edit {
  background-color: #42ba85;
  color: white;
  margin-right: 5px;
  width: 7vw;
  float: right;
  max-width: 150px;
  min-width: 110px;
}

.btn-Edit:hover {
  background-color: #35976d;
  color: white;
}

#editDomainName {
  position: relative;
  float: right;
  margin-right: 6rem;
}

#domainEditName {
  position: relative;
  float: right;
  margin-right: 6rem;
}
#editDomainRoles {
  position: relative;
  float: right;
  margin-right: 6rem;
}

#editDomainInfo {
  position: relative;
  float: right;
  margin-right: 6rem;
  margin-bottom: 2rem;
}
/* --------------- CREATE MODAL STYLE--------------------- */
#domainName {
  position: relative;
  float: right;
  margin-right: 6rem;
}
#domainInfo {
  position: relative;
  float: right;
  margin-right: 6rem;
}

#domainsTitle {
  position: relative;
  top: 20px;
  left: 10px;
}

.usernameText {
  margin-right: 15px;
}

.domainsTableRow:hover {
  color: grey;
  cursor: pointer;
}

.domainBackGround {
  background-color: #2a323d;
  width: 100vw;
  height: 100vh;
  overflow-y: visible;
  overflow-x: hidden;
}

#domainCreateButton {
  background-color: #2a323d;
  color: #42ba85;
  position: relative;
  right: 12px;
  width: 110px;
  max-width: 150px;
  min-width: 110px;
}

#domainCreateButton:hover {
  background-color: #42ba85;
  color: black;
}

td {
  font-size: clamp(12px, 1.5vw, 1rem);
}
.modal-body {
  font-size: clamp(12px, 1.5vw, 1rem);
}
@media (max-width: 500px) and (min-width: 450px) {
  .navText {
    display: none;
  }
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonDomains {
    right: 0px;
  }

  /*----------- Navbar -------------*/
  #navButtonHomeDomains {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }

  #navButtonUsersDomains {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonActiveDomains {
    font-size: 100%;
    min-width: 10%;
  }
  #navButtonDevicesDomains {
    font-size: 100%;
    min-width: 10%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDomains {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDomains {
    font-size: 14px;
    margin-right: 30px;
    min-width: 10px;
  }

  .btn-Edit {
    float: left;
    min-width: 150%;
  }

  #cancelDomainEdit {
    width: 110px;
    min-width: 110px;
    font-size: 14px;
  }
  .btn-danger {
    min-width: 150%;
    float: left;
  }

  #cancelButtonDomainsCreate {
    min-width: 110px;
  }
  #domainName {
    float: right;
    margin-right: 50px;
  }
  #domainInfo {
    float: right;
    margin-right: 50px;
  }
  #domainCreateButton {
    width: 50px;
    align-items: center;
    right: 0px;
  }

  td {
    font-size: clamp(12px, 1.5vw, 1rem);
  }
  .modal-body {
    font-size: clamp(12px, 1.5vw, 1rem);
  }
  /* --------------------DOMAINS CARD----------------------- */
  .domainsCard {
    margin: auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #domainsTable {
    max-width: 85vw;
  }
  #tableCells {
    font-size: 12px;
  }
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }

  #hiddenCreateText {
    display: none;
  }
  #domainCreateButton {
    min-width: 10%;
    max-width: 150px;
    align-items: center;
    margin-right: 7.5vw;
  }
}
@media (max-width: 1010px) and (min-width: 501px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonDomains {
    right: 0px;
  }

  /*----------- Navbar -------------*/
  #navButtonHomeDomains {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  #navButtonUsersDomains {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  #navButtonActiveDomains {
    font-size: 14px;
    min-width: 105px;
    left: 10px;
  }
  #navButtonDevicesDomains {
    font-size: 14px;
    min-width: 95px;
    left: 10px;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDomains {
    font-size: 14px;
    margin-right: 30px;
    min-width: 10px;
  }
  /* --------------------DOMAINS CARD----------------------- */
  .domainsCard {
    margin: auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #domainsTable {
    max-width: 85vw;
  }
  #tableCells {
    font-size: 12px;
  }
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }

  #hiddenCreateText {
    display: none;
  }
  #domainCreateButton {
    min-width: 10%;
    max-width: 150px;
    align-items: center;
    margin-right: 7.5vw;
  }
}

@media (max-width: 450px) and (min-width: 350px) {
  .btn-Edit {
    float: left;
    min-width: 150%;
  }

  #cancelDomainEdit {
    width: 110px;
    min-width: 110px;
    font-size: 14px;
  }
  .btn-danger {
    min-width: 150%;
    float: left;
  }

  /* --------------------DOMAINS CARD----------------------- */
  .domainsCard {
    margin: auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #domainsTable {
    max-width: 85vw;
  }
  #tableCells {
    font-size: 8px;
  }
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }

  #hiddenCreateText {
    display: none;
  }
  #domainCreateButton {
    min-width: 10%;
    max-width: 14%;
    align-items: center;
    margin-right: 2%;
  }
  /*----------- Navbar -------------*/
  #navButtonHomeDomains {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }

  #navButtonUsersDomains {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonActiveDomains {
    font-size: 100%;
    min-width: 10%;
  }
  #navButtonDevicesDomains {
    font-size: 100%;
    min-width: 10%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDomains {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  .usernameText {
    display: none;
  }

  #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  /* --------------- CREATE MODAL STYLE--------------------- */

  #cancelButtonDomainsCreate {
    min-width: 110px;
  }
  #domainName {
    float: right;
    margin-right: 50px;
  }
  #domainInfo {
    float: right;
    margin-right: 50px;
  }
}
@media (max-width: 1260px) and (min-width: 1010px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonDomains {
    right: 0px;
  }
}

/*----------------------------- Logo ---------------------------*/

* {
  box-sizing: border-box;
}
.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  margin-top: 2.5px;
  margin-left: 10px;
}
.item {
  align-items: center;
  display: flex;
  height: 7.5px;
  justify-content: center;
  position: relative;
  width: 7.5px;
  z-index: 100;
  img {
    object-fit: cover;
    height: 45px;
    width: 45px;
  }
  &::before,
  &::after {
    background-image: linear-gradient(-180deg, #0076a3 0%, #072b3c 97%);
    content: "";
    height: 7.5px;
    position: absolute;
    transition: all 0.2s;
    transition-delay: 0.2s;
    transform: translateY(0px) translateX(0px);
    width: 7.5px;
    z-index: -1;
  }
  &::after {
    background-image: linear-gradient(0deg, #8c9a27 0%, #d3d821 97%);
  }
  &:hover {
    animation-name: hoverPop;
    animation-duration: 0.4s;
    &::before {
      animation: slick-hover-2 3s 0.4s linear infinite;
      transform: translateY(-20px) translateX(-20px);
    }
    &::after {
      animation: slick-hover 3s 0.4s linear infinite reverse;
      transform: translateY(20px) translateX(20px);
    }
  }
}

@keyframes hoverPop {
  0%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(0.9);
  }
}

@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px);
  }
  16.67% {
    transform: translateY(-13px) translateX(-9px);
  }
  33.33% {
    transform: translateY(4px) translateX(12px);
  }
  50.00% {
    transform: translateY(20px) translateX(-20px);
  }
  66.67% {
    transform: translateY(-3px) translateX(8px);
  }
  83.33% {
    transform: translateY(-15px) translateX(-11px);
  }
  100.00% {
    transform: translateY(20px) translateX(20px);
  }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px);
  }
  16.67% {
    transform: translateY(3px) translateX(-5px);
  }
  33.33% {
    transform: translateY(18px) translateX(-10px);
  }
  50.00% {
    transform: translateY(-20px) translateX(20px);
  }
  66.67% {
    transform: translateY(15px) translateX(-4px);
  }
  83.33% {
    transform: translateY(-1px) translateX(15px);
  }
  100.00% {
    transform: translateY(-20px) translateX(-20px);
  }
}
@media (max-width: 350px) {
  td {
    font-size: clamp(7px, 0.5vw, 0.4rem);
  }
  .modal-body {
    font-size: 7px;
  }
  #domainsTitle {
    font-size: 12px;
  }
  #searchDiv {
    font-size: 10px;
  }
  #searchBar {
    width: 80px;
    min-width: 80px;
    height: 30px;
  }
  .btn-danger {
    margin-right: 5px;
    width: 7vw;
    min-width: 120%;
    font-size: 150%;
    max-width: 150%;
  }
  #domainDeleteButton {
    min-width: 150%;
    float: left;
  }
  #searchButton {
    height: 30px;
    min-width: 30px;
    width: 14%;
  }
  .btn-Edit {
    float: left;
    min-width: 120%;
    font-size: 150%;
    left: 0vw;
  }
  .btn-danger {
    min-width: 120%;
    font-size: 150%;
    float: left;
    left: 0vw;
  }

  /* --------------------DOMAINS CARD----------------------- */
  .domainsCard {
    margin: auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #domainsTable {
    max-width: 85vw;
  }
  #tableCells {
    font-size: 6px;
  }
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }

  #hiddenCreateText {
    display: none;
  }
  #domainCreateButton {
    min-width: 10%;
    width: 13%;
    max-width: 14%;
    font-size: 10px;
    margin-right: 15px;
  }
  /*----------- Navbar -------------*/
  #navButtonHomeDomains {
    min-width: 12%;
    margin-left: 0%;
    font-size: 80%;
  }

  #navButtonUsersDomains {
    min-width: 12%;
    margin-left: 0%;
    font-size: 80%;
  }

  #navButtonActiveDomains {
    min-width: 12%;
    margin-left: 0%;
    font-size: 80%;
  }
  #navButtonDevicesDomains {
    min-width: 12%;
    margin-left: 0%;
    font-size: 80%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDomains {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  .usernameText {
    display: none;
  }

  #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }
  #cancelButtonDomainsCreate {
    min-width: 110px;
    font-size: 90%;
  }
  #domainName {
    float: right;
    margin-right: 30px;
  }
  #domainInfo {
    float: right;
    margin-right: 30px;
  }
}
</style>
